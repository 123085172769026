import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "management-sidebar" }
const _hoisted_2 = { class: "back-button-container" }
const _hoisted_3 = { class: "search-container" }
const _hoisted_4 = { class: "sidebar-section-container" }
const _hoisted_5 = {
  key: 0,
  class: "sidebar-section"
}
const _hoisted_6 = ["onClick"]
const _hoisted_7 = {
  key: 0,
  style: {"margin-left":"auto"}
}
const _hoisted_8 = {
  key: 0,
  class: "sub-items-container"
}
const _hoisted_9 = { class: "sub-item-title" }
const _hoisted_10 = { class: "description" }
const _hoisted_11 = {
  key: 0,
  class: "empty-search-container"
}

import { BadgeProps } from '@/modules/shared';
import _ from 'lodash';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from '@/store';
import { getQueryAsString } from '@/modules/shared/utils/query';
import { canAccessFeature } from '@/middlewares/guard';
import Badge from '@/modules/shared/components/atoms/badge/Badge.vue';
import Input from '../../../modules/shared/components/atoms/input/Input.vue';

interface SidebarSubItem {
  title: string;
  description: string;
  path: string;
}

interface SidebarItem {
  sectionTitle: string;
  sectionIcon: string;
  key: string;
  subItems?: SidebarSubItem[];
  path?: string;
  nonCollapsible?: boolean;
  badge?: BadgeProps;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'TeamManagementSidebar',
  setup(__props) {

const { t } = useI18n();
const store = useStore();
const route = useRoute();
const router = useRouter();

const q = ref<string>('');

const teamFeatures = computed(() => store.state.teamFeatures.teamFeatures);
const isManagerOrAdmin = computed(() => store.getters.isManagerOrAdmin);

const teamName = getQueryAsString(route.params, 'teamName');

const isCollapse = ref<Record<string, boolean>>({
  secure: false,
  videoPublishing: false,
  bumper: false,
  subtitle: false,
  videoOutput: false,
  videoInformation: false,
  importSettings: false,
  collection: false,
  plugIn: false,
});

const secureSubItems = computed<SidebarSubItem[]>(() => {
  const result = [
    {
      title: 'Geo-blocking Rules',
      description: t('teamSettings.sidebar.geoblock.description'),
      path: 'geo-blocking',
    },
    {
      title: 'Allowed/Blocked Websites',
      description: t('teamSettings.sidebar.accessControlPolicy.description'),
      path: 'allowed-blocked-website',
    },
    {
      title: 'Signed URL',
      description: t('teamSettings.sidebar.signedUrl.description'),
      path: 'signed-url',
    },
    {
      title: 'ClearKey Encryption',
      description: t('teamSettings.sidebar.clearKey.description'),
      path: 'clear-key',
    },
  ];

  if (canAccessFeature('multi-drm') && isManagerOrAdmin.value) {
    result.push({
      title: 'Multi-DRM',
      description: t('teamSettings.sidebar.multiDrm.description'),
      path: 'multi-drm',
    });
  }

  return result;
});

const sidebarItems = computed<SidebarItem[]>(() => [
  {
    sectionTitle: 'Secure & Protect',
    sectionIcon: 'fa-shield-alt',
    key: 'secure',
    subItems: secureSubItems.value,
  },
  {
    sectionTitle: 'Video Publishing',
    sectionIcon: 'fa-share-alt',
    key: 'videoPublishing',
    subItems: [
      {
        title: 'Video Embed Players',
        description: t('teamSettings.sidebar.player.description'),
        path: 'players',
      },
      {
        title: 'Playback Channels',
        description: t('teamSettings.sidebar.playbackChannel.description'),
        path: 'playback-channels',
      },
    ],
  },
  {
    sectionTitle: 'Bumper',
    sectionIcon: 'fa-play-circle',
    key: 'bumper',
    permissionRoles: ['user', 'manager'],
    subItems: [
      {
        title: 'Bumpers',
        description: t('teamSettings.sidebar.manageBumper.description'),
        path: 'bumpers',
      },
      {
        title: 'Bumper Insertion',
        description: t('teamSettings.sidebar.applyBumper.description'),
        path: 'bumper-insertion',
      },
    ],
  },
  {
    sectionTitle: 'Subtitle',
    sectionIcon: 'fa-message-captions',
    key: 'subtitle',
    subItems: [
      {
        title: 'Auto-generated Subtitle Profiles',
        description: t('teamSettings.sidebar.subtitleprofile.description'),
        path: 'subtitle-profiles',
      },
      {
        title: 'Bad Word List',
        description: t('teamSettings.sidebar.badwordlist.description'),
        path: 'badword-lists',
      },
    ],
  },
  {
    sectionTitle: 'Video Output',
    sectionIcon: 'fa-video',
    key: 'videoOutput',
    subItems: [
      {
        title: 'Presets',
        description: t('teamSettings.sidebar.preset.description'),
        path: 'presets',
      },
    ],
  },
  {
    sectionTitle: 'Video Information',
    sectionIcon: 'fa-rectangle-list',
    key: 'videoInformation',
    subItems: [
      {
        title: 'Custom Fields',
        description: t('project.manage.customFields.description'),
        path: 'custom-fields',
      },
      {
        title: 'Custom Image Fields',
        description: t('project.manage.customImageFields.description'),
        path: 'custom-image-fields',
      },
    ],
  },
  {
    sectionTitle: 'Import Settings',
    sectionIcon: 'fa-solid fa-file-import',
    key: 'importSettings',
    subItems: [
      {
        title: t('project.manage.videoSources.title'),
        description: t('project.manage.videoSources.description'),
        path: 'video-sources',
      },
    ],
  },
  {
    sectionTitle: 'Collection',
    sectionIcon: 'fa-rectangle-history',
    key: 'collection',
    subItems: [
      {
        title: 'Custom Collection Types',
        description: t('project.manage.collectionType.description'),
        path: 'collection-type',
      },
    ],
  },
  {
    sectionTitle: 'ByteArk Lighthouse Integration',
    sectionIcon: 'fa-chart-line',
    key: 'lighthouse',
    subItems: [
      {
        title: 'Metadata',
        description: 'Manage data dimension for Lighthouse',
        path: 'lighthouse',
      },
    ],
  },
  {
    sectionTitle: 'Plug-in',
    sectionIcon: 'fa-plug rotate',
    key: 'plugIn',
    subItems: [
      {
        title: 'Uploader Forms',
        description: t('teamSettings.sidebar.formUpload.description'),
        path: 'forms',
      },
    ],
  },
  {
    sectionTitle: t('teamSettings.header.title'),
    sectionIcon: 'fa-gear',
    key: 'teamSettings',
    path: 'team-settings',
    nonCollapsible: true,
    badge: {
      title: t('common:default'),
      badgeStyle: 'info',
      theme: 'dark',
      size: 'small',
    },
  },
]);

const filteredSidebarItems = computed<SidebarItem[]>(() => {
  const searchTerm = q.value.toLowerCase();

  if (!searchTerm) {
    return sidebarItems.value;
  }

  const subItemMatcher = (item: SidebarSubItem) => item.title.toLowerCase().includes(searchTerm) || item.description.toLowerCase().includes(searchTerm);

  return sidebarItems.value
    .map((item) => {
      /** เช็กว่า match กับ sectionTitle ของ collapsible section หรือไม่ */
      const sectionTitleMatched = item.sectionTitle.toLowerCase().includes(searchTerm);
      /** เช็กว่า match กับ title หรือ description ของ subItems อย่างน้อย 1 อันหรือไม่ */
      const subItemsMatched = item.subItems?.some(subItemMatcher);

      const matched = subItemsMatched || sectionTitleMatched;

      if (!matched) {
        return undefined;
      }

      return {
        ...item,
        /**
         * ถ้า match เฉพาะ sectionTitle ของ collapsible section ให้แสดง subitems ทั้งหมดของมันออกมาเลย ไม่งั้นมันจะเป็นแค่ collapsible ที่ทำอะไรไม่ได้เลย
         * แต่ถ้า match subitems ก็เอาเฉพาะ submenu ที่ matched กับคำค้นหา
         * */
        subItems: sectionTitleMatched && !subItemsMatched ?
          item.subItems
          : item.subItems?.filter(subItemMatcher),
      };
    })
    /** filter เอา undefined (!matched) ออก (ใช้ flatMap แทน filter เนื่องจาก TypeScript Error) */
    .flatMap((item) => item || []);

  /**
   * Expected Results:
   * ค้นหาว่า "ตั้งค่า" เจอผลลัพธ์:
   *   Video Publishing        <- Collapsible Section
   *     Video Embed Players
   *     ตั้งค่าตัวเล่นวิดีโอ embed    <- Matched
   *     Playback Channels
   *     ตั้งค่าลิงก์วิดีโอและควบคุมสิทธิ์การเข้าชม    <- Matched
   *   Subtitle                <- Collapsible Section
   *     Auto-generated Subtitle Profiles
   *     ตั้งค่า Subtitle profile สำหรับใช้ Auto-generated วิดีโอ   <- Matched
   *   ตั้งค่าเริ่มต้นโปรเจกต์  <- Non-collapsible Section; Matched
   */
});

const isEmptySearch = computed(() => !_.isEmpty(q.value) && _.isEmpty(filteredSidebarItems.value));

function onToggleCollapse(key: string) {
  isCollapse.value[key] = !isCollapse.value[key];
}

function isTeamFeature(key: string) {
  return teamFeatures.value && teamFeatures.value[key];
}

function canShowSubSection(subSection: SidebarSubItem) {
  if (subSection.path === 'custom-image-fields') {
    return isTeamFeature('custom-image');
  }

  if (subSection.path === 'subtitle-profiles') {
    return isTeamFeature('auto-generate-caption');
  }
  return true;
}

function canShowSection(section: SidebarItem) {
  if (section.key === 'bumper') {
    return isTeamFeature('bumper');
  }
  return true;
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_link, {
        to: `/${_unref(teamName)}/projects`
      }, {
        default: _withCtx(() => [
          _cache[1] || (_cache[1] = _createElementVNode("i", { class: "fa fa-chevron-left" }, null, -1)),
          _createTextVNode(" " + _toDisplayString(_unref(t)('common:button.back')), 1)
        ]),
        _: 1
      }, 8, ["to"])
    ]),
    _cache[2] || (_cache[2] = _createElementVNode("h1", { class: "sidebar-title" }, "Management", -1)),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(Input, {
        modelValue: q.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((q).value = $event)),
        placeholder: _unref(t)('common:form.searchBy', { item: _unref(t)('glossary:menu')}),
        isSearchInput: ""
      }, null, 8, ["modelValue", "placeholder"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredSidebarItems.value, (sidebarItem) => {
        return (_openBlock(), _createElementBlock("div", {
          key: sidebarItem.key
        }, [
          (canShowSection(sidebarItem))
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("div", {
                  class: "section-title",
                  onClick: () => {
              if (sidebarItem.path) {
                _unref(router).push(`/${_unref(teamName)}/manage/${sidebarItem.path}`);
              } else if (!sidebarItem.nonCollapsible) {
                onToggleCollapse(sidebarItem.key)
              }
            }
                }, [
                  _createElementVNode("i", {
                    class: _normalizeClass(`section-icon fas ${sidebarItem.sectionIcon}`)
                  }, null, 2),
                  _createTextVNode(" " + _toDisplayString(sidebarItem.sectionTitle) + " ", 1),
                  (sidebarItem.badge)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_7, [
                        _createVNode(Badge, {
                          title: sidebarItem.badge.title,
                          badgeStyle: sidebarItem.badge.badgeStyle,
                          theme: sidebarItem.badge.theme,
                          size: sidebarItem.badge.size
                        }, null, 8, ["title", "badgeStyle", "theme", "size"])
                      ]))
                    : _createCommentVNode("", true),
                  (!sidebarItem.nonCollapsible)
                    ? (_openBlock(), _createElementBlock("i", {
                        key: 1,
                        class: _normalizeClass(["fas fa-chevron-down chevron-icon", { 'flip': !isCollapse.value[sidebarItem.key] }])
                      }, null, 2))
                    : _createCommentVNode("", true)
                ], 8, _hoisted_6),
                (!isCollapse.value[sidebarItem.key] && !sidebarItem.nonCollapsible)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sidebarItem.subItems, (subItem) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: subItem.path
                        }, [
                          (canShowSubSection(subItem))
                            ? (_openBlock(), _createBlock(_component_router_link, {
                                key: 0,
                                class: "link",
                                to: `/${_unref(teamName)}/manage/${subItem.path}`
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_9, _toDisplayString(subItem.title), 1),
                                  _createElementVNode("div", _hoisted_10, _toDisplayString(subItem.description), 1)
                                ]),
                                _: 2
                              }, 1032, ["to"]))
                            : _createCommentVNode("", true)
                        ]))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ]))
      }), 128)),
      (isEmptySearch.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(_unref(t)('common:search.resultEmpty.title', { q: q.value })), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})